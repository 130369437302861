import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/plugins/i18n';
import router from './router';
import LoadScript from 'vue-plugin-load-script';

import App from './App.vue';
import '@/styles/app.css';
import '@/styles/fonts.css';
import '@/styles/scrollbar.css';

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(LoadScript);

app.mount('#app');

// Handling Global components TS types for intellisense
// import { defineCustomElement } from '@vue/runtime-dom';

// declare module '@vue/runtime-core' {
// 	export interface GlobalComponents {

// 	}
// }