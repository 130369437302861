<script lang="ts" setup>
</script>

<template>
  <div class="top-0 left-0 w-full z-[2]">
    <div class="grid grid-cols-2 items-center justify-center w-full h-[71px] px-5 z-30 bg-test-gray-200">
      <router-link
        class="ml-0 mr-auto py-2.5"
        :to="{ name: 'home' }"
      >
        <h1 class="text-5xl leading-none">
          Test
        </h1>
      </router-link>
      <div class="flex justify-end align-center">
        <router-link
          :to="{ name: 'home' }"
          class="px-2"
        >
          Type 1
        </router-link>
        <router-link
          :to="{ name: 'home-2' }"
          class="px-2"
        >
          Type 2
        </router-link>
        <!-- <router-link
          :to="{ name: 'home-3' }"
          class="px-2"
        >
          3
        </router-link> -->
      </div>
    </div>
  </div>
</template>