export const footerUrls = [
  {
    label: 'IMPRINT',
  },
  {
    label: 'PRIVACY-POLICY',
  },
  {
    label: 'GENERAL-TERMS-AND-CONDITIONS',
  },
];