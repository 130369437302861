
import { useLoadFont } from '@/composables/usePreloadAsset';
import HeaderNav from '@/components/header/HeaderNav.vue';
import FooterSection from '@/components/FooterSection.vue';


function preloadAsset () {
  useLoadFont([
    {
      name: 'TTHoves-Bold',
      path: `url(${require('@/assets/fonts/TTHoves-Bold.ttf')})`,
    },
    {
      name: 'TTHoves-BoldItalic',
      path: `url(${require('@/assets/fonts/TTHoves-BoldItalic.ttf')})`,
    },
    {
      name: 'TTHoves-DemiBold',
      path: `url(${require('@/assets/fonts/TTHoves-DemiBold.ttf')})`,
    },
    {
      name: 'TTHoves-DemiBoldItalic',
      path: `url(${require('@/assets/fonts/TTHoves-DemiBoldItalic.ttf')})`,
    },
    {
      name: 'TTHoves-Medium',
      path: `url(${require('@/assets/fonts/TTHoves-Medium.ttf')})`,
    },
    {
      name: 'Sofia Pro Regular',
      path: `url(${require('@/assets/fonts/SofiaProRegularAz.otf')})`,
    },
    {
      name: 'Sofia Pro Semi Bold',
      path: `url(${require('@/assets/fonts/SofiaProSemiBoldAz.otf')})`,
    },
  ]);
}

export default {
  components: {
    HeaderNav,
    FooterSection,
  },
  created() {
    preloadAsset();
  },
};
