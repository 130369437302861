import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainLayout,
    children: [
      // ...
      {
        path: '',
        redirect: {
          name: 'home',
        },
      },
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
        meta: {
          routeParent: 'home',
        },
      },
      {
        path: 'home-2',
        name: 'home-2',
        component: () => import(/* webpackChunkName: "home-2" */ '../views/home-2/index.vue'),
        meta: {
          routeParent: 'home',
        },
      },
      {
        path: 'home-3',
        name: 'home-3',
        component: () => import(/* webpackChunkName: "home-3" */ '../views/home-3/index.vue'),
        meta: {
          routeParent: 'home',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      path: '/de/home',
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
